export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Tech Solutions",
  headline: "Contact Us For All Your Technology Needs",
  description:
    "With technology ever-changing and becoming more difficult to integrate, set up, and configure, we would like to offer a service that takes the pain and frustration out of setting up new technology. Let us help you overcome all these challenges and book an appointment now.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/sink.svg"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Book an Appointment",
  headline: "Get a Quote",
  description:
    "Whether you are a homeowner, small business manager, or a large enterprise company we can assist you with your technological necessities, our tech support is tailored to our customers and their specific wants and needs.",
  buttonLabel: "More",
  imgStart: "",
  img: require("../../images/money.svg"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "A Little About Us",
  description:
    "Analysis Solutions is a small Australian-owned and run tech support and consulting company based out of Melbourne's North-Eastern suburbs. We aim to make the experience of tech support seamless and easy, allowing for our customers to get on with their day free of issues. We specialise in both home and advanced business support offering you the services you require.",
  buttonLabel: "More",
  imgStart: "start",
  img: require("../../images/AS.png"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Service Area",
  headline: "North-Eastern Melbourne",
  description:
    "Analysis Solutions is an Eltham-based tech company servicing the North-Eastern suburbs of Melbourne. Our wide service radius allows us to bring the support right to your front door, so what are you waiting for? Give us a call to get your free quote today.",
  buttonLabel: "Contact",
  imgStart: "start",
  img: require("../../images/map.svg"),
  alt: "Vault",
  start: "true",
};
