import React from 'react';
//import { Link } from 'react-router-dom';
import { Container, /*Button*/ } from './globalStyles';
import {
  ErrorSec,
  ErrorRow,
  ErrorColumn,
  ErrorTextWrapper,
  ErrorTopLine,
  ErrorHeading,
  ErrorSubtitle,
  ErrorImgWrapper,
  ErrorImg
} from './Error.element';

function Error({
  
}) {
  return (
    <>
      <ErrorSec lightBg = {false}>
        <Container>
          <ErrorRow>
            <ErrorColumn>
              <ErrorTextWrapper>
                <ErrorTopLine lightTopLine={true}>Error</ErrorTopLine>
                <ErrorHeading lightText={true}>404</ErrorHeading>
                <ErrorSubtitle lightTextDesc={true}>We are so sorry for the inconvenience but it appears that the page you are looking for does not exist </ErrorSubtitle>
                
              </ErrorTextWrapper>
            </ErrorColumn>
            <ErrorColumn>
              <ErrorImgWrapper>
                <ErrorImg src={require('./images/404.svg')} alt={'Credit Card'} /> 
              </ErrorImgWrapper>
            </ErrorColumn>
          </ErrorRow>
        </Container>
      </ErrorSec>
    </>
  );
}

export default Error;
