export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Home Solutions',
  description:
    'Feeling overwhelmed in a world surrounded by technology? Having trouble trying to setup and configure your new gadgets? Well at Analysis Solutions we offer our customers the help and support they need, straight to their front door. We will send out one of our IT Consultants to setup and assist you with all your technology needs, removing the hassle and pain from doing it yourself.',
  secdescription:'Pensioners Discounts Apply',
  buttonLabel: 'Book',
  imgStart: '',
  img: require('../../images/wifi.svg'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '100% Secure',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Small Business Solutions',
  description:
    "Starting a small business but are having trouble with setting up the technology required for you to run it? Well, we can help you out with just one quick phone call. At Analysis Solutions we also offer support and advice for those looking for new Domains and Website creation. So you can get your website up and running as fast as possible.",
  secdescription: 'Computer Support: Need help picking a new computer that suits your needs? Or just have a computer that is not working the way you want it to? We offer support to help our customers with any computer requirements.',
  buttonLabel: 'Book',
  imgStart: 'start',
  img: require('../../images/security.svg'),
  alt: 'Vault'
};

export const homeObjFive = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Advanced Solutions',
  description:
    'ERP Consulting: Want To Improve Your Efficiency? Confused on the operations of some softwares? We offer ERP consulting that can provide you a better understanding of how software systems and applications work, meaning that you can become more efficient and productive with your work.',
  secdescription: 'Business Integration: Our integration development team can deliver a solution linking your ERP System to other third-party or standalone applications. For example: Linking your current ERP System to a Third-Party Logistics Application.',
  buttonLabel: 'Book',
  imgStart: '',
  img: require('../../images/advanced.svg'),
  alt: 'Credit Card'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Data Analytics',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};
