export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'Meet the Team',
  headline: 'Ralf Haase',
  description:
    '"I started my days off as a costing accountant valuing the work experience gained. Soon after I joined the customer support sector as an ERP consultant where I remained for a duration of 15 years. Then I moved into business consulting which marks the beginning of Analysis Solutions."',
  buttonLabel: 'Contact',
  Sheadling: 'Jayden Wade',
  Sdescription: 'Home services consultant for Analysis Solutions, working my way towards a Bachelor of Information Technology at RMIT. "I have always had a passion in technology and love giving my services back to my community."',
  imgStart: '',
  img: require('../../images/image.png'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Appointment Times',
  headline: 'Opening Hours',
  description:
    'Monday - Saturday: 10am - 5pm',
  buttonLabel: 'Return',
  Sdescription: 'Sunday: Closed',
  imgStart: '',
  img: require('../../images/question.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Easy Setup',
  headline: 'Super fast and simple onboarding process',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};
