import React from 'react';
import { Link } from 'react-router-dom';
import { Container, /*Button*/ } from '../../globalStyles';
import {
  AboutUsInfoSec,
  AboutUsInfoRow,
  AboutUsInfoColumn,
  AboutUsTextWrapper,
  AboutUsTopLine,
  AboutUsHeading,
  AboutUsSubtitle,
  AboutUsImgWrapper,
  AboutUsImg
} from './AboutUs.elements';

function AboutUs({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
  Sheadling,
  Sdescription
}) {
  return (
    <>
      <AboutUsInfoSec lightBg={lightBg}>
        <Container>
          <AboutUsInfoRow imgStart={imgStart}>
            <AboutUsInfoColumn>
              <AboutUsTextWrapper>
                <AboutUsTopLine lightTopLine={lightTopLine}>{topLine}</AboutUsTopLine>
                <AboutUsHeading lightText={lightText}>{headline}</AboutUsHeading>
                <AboutUsSubtitle lightTextDesc={lightTextDesc}>{description}</AboutUsSubtitle>
                <AboutUsHeading lightText={lightText}>{Sheadling}</AboutUsHeading>
                <AboutUsSubtitle lightTextDesc={lightTextDesc}>{Sdescription}</AboutUsSubtitle>
                <Link to='/products'>
                </Link>
              </AboutUsTextWrapper>
            </AboutUsInfoColumn>
            <AboutUsInfoColumn>
              <AboutUsImgWrapper start={start}>
                <AboutUsImg src={img} alt={alt} />
              </AboutUsImgWrapper>
            </AboutUsInfoColumn>
          </AboutUsInfoRow>
        </Container>
      </AboutUsInfoSec>
    </>
  );
}

export default AboutUs;
