import React from 'react';
import { Button } from '../../globalStyles';
import {
  FaEnvelopeOpenText,
  FaPhoneSquareAlt
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  //FormInput,
  //FooterLinksContainer,
  //FooterLinksWrapper,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';

function Footer() {
  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Get in Contact with Us
        </FooterSubHeading>
        <FooterSubText>It all starts with "Hello"</FooterSubText>
        <Form >
          <SocialLogo to='/sign-up'>
            <Button>Get in Contact</Button>
          </SocialLogo>
        </Form>
      </FooterSubscription>
      
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/sign-up'>
            <SocialIcon />
            ANALYSIS SOLUTIONS
          </SocialLogo>
          <WebsiteRights>ANALYSIS SOLUTIONS © 2022</WebsiteRights>
          <SocialIcons>
          <SocialIconLink href='mailto:enquiries@analysissolutions.org?subject=Sent from the Web' target='_blank' aria-label='Email'>
              <FaEnvelopeOpenText />
          </SocialIconLink>
          <SocialIconLink href='tel:0491750338' target='_blank' aria-label='Phone'>
              <FaPhoneSquareAlt />
          </SocialIconLink>  
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}



export default Footer;
