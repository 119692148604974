import React from 'react';
//import { Button } from '../../globalStyles';
//import { AiFillThunderbolt } from 'react-icons/ai';
//import { GiCrystalBars } from 'react-icons/gi';
//import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { AiFillHome } from 'react-icons/ai';
import { MdBusinessCenter } from 'react-icons/md';
//import { GiFloatingCrystal } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { BiBuildings } from 'react-icons/bi';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
  Discount
} from './Pricing.elements';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Services</PricingHeading>
          <Discount>
            <PricingCardLength>Pensioner Discounts Apply</PricingCardLength>
          </Discount>
          <PricingContainer>
            <PricingCard to='/services'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <AiFillHome />
                </PricingCardIcon>
                <PricingCardPlan>Home Configuration</PricingCardPlan>
                <PricingCardCost>$79.99</PricingCardCost>
                <PricingCardLength>first hour, proceeding hours $50</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Wi-Fi Setup</PricingCardFeature>
                  <PricingCardFeature>Google Home / Alexa</PricingCardFeature>
                  <PricingCardFeature>Printer Configuration</PricingCardFeature>
                  <PricingCardFeature>Phone/Watch Setup</PricingCardFeature>
                  <PricingCardFeature>Smart TV Setup</PricingCardFeature>
                  <PricingCardFeature>Home Accessories</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/services'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <MdBusinessCenter />
                </PricingCardIcon>
                <PricingCardPlan>Small Business</PricingCardPlan>
                <PricingCardCost>$109.99</PricingCardCost>
                <PricingCardLength>first hour, proceeding hours $50</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Computer Support</PricingCardFeature>
                  <PricingCardFeature>Domain Advice</PricingCardFeature>
                  <PricingCardFeature>Security Cameras</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard to='/services'>
              <PricingCardInfo>
                <PricingCardIcon>
                  <BiBuildings />
                </PricingCardIcon>
                <PricingCardPlan>Advanced Solutions</PricingCardPlan>
                <PricingCardCost>Get A Quote</PricingCardCost>
                <PricingCardLength></PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>ERP Consulting & Training</PricingCardFeature>
                  <PricingCardFeature>Accounting</PricingCardFeature>
                  <PricingCardFeature>Business Integration</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
          
        </PricingWrapper>
        <Discount>
            <PricingCardLength>Still have questions about the services we provide? Give us a Call.</PricingCardLength>
          </Discount>
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;
