import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Button } from '../../globalStyles';
import {
  DetailsInfoSec,
  DetailsInfoRow,
  DetailsInfoColumn,
  DetailsTextWrapper,
  DetailsTopLine,
  DetailsHeading,
  DetailsSubtitle,
  DetailsImgWrapper,
  DetailsImg,
  DetailLinks
} from './Details.elements';



function Details({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
  btndestination,
  
}) {
  return (
    <>
      <DetailsInfoSec lightBg={lightBg}>
        <Container>
          <DetailsInfoRow imgStart={imgStart}>
            <DetailsInfoColumn>
              
              <DetailsTextWrapper>
                <DetailsTopLine lightTopLine={lightTopLine}>{topLine}</DetailsTopLine>
                <DetailsHeading lightText={lightText}>{headline}</DetailsHeading>
                <DetailsSubtitle lightTextDesc={lightTextDesc}>{description}</DetailsSubtitle>
                
              </DetailsTextWrapper>
              
            </DetailsInfoColumn>
            <DetailsInfoColumn>
              <DetailsImgWrapper start={start}>
                <DetailsImg src={img} alt={alt} />
              </DetailsImgWrapper>
            </DetailsInfoColumn>
          </DetailsInfoRow>
        </Container>
      </DetailsInfoSec>
    </>
  );
}


/* <DetailLinks>
                  <Link to='/'>
                    <Button>Call Me</Button>
                  </Link>
                </DetailLinks>
*/

export default Details;
