export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Call Us',
  headline: '0418990121',
  description:
    'Give us a call and see what we can do for you.',
  buttonLabel: 'Sign Up',
  imgStart: '',
  img: require('../../images/call.svg'),
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: '100% Secure',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'enquiries@analysissolutions.org',
  headline: 'Send us an Email',
  description:
    "Send Us Your Information And We Will Discuss How We Can Help",
  buttonLabel: 'Start Now',
  btndestination: 'mailto:enquiries@analysissolutions.org?subject=Sent from the Web',
  imgStart: 'start',
  img: require('../../images/email.svg'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Data Analytics',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};
